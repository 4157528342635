export default {
  data: () => ({
    query: '',
    selected: {},
    suggestions: [{
      data: []
    }],
    inputProps: {
      id: 'autosuggest__input_ajax',
      class: 'form-control',
      placeholder: 'Buscar'
    },
    timeout: null,
    debounceMilliseconds: 250
  }),
  methods: {
    onSelected (item) {
      this.selected = item.item
    },
    onInputChange () {
      const { query } = this

      if (query.length === 0) {
        return
      }

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        const url = `/admin/users/paginate/5?page=1&filterBy=${this.filterBy}&filter=${query}`
        this.$http.get(url)
          .then(res => {
            this.suggestions[0].data = res.data.data
          })
      }, this.debounceMilliseconds)
    },
    currencyFormat (val) {
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })

      return formatter.format(val)
    },
    /**
         * This is what the <input/> value is set to when you are selecting a suggestion.
         */
    getSuggestionValue (suggestion) {
      const balance = this.currencyFormat(suggestion.item.balance.amount)
      return `${suggestion.item.personal_data.full_name} | ${suggestion.item.email} | ${suggestion.item.personal_data.phone} | ${balance}`
    }
  }
}
