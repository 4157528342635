<template>
  <b-card v-if="show && Object.keys(user).length > 0">
    <b-card-title>
      <div>
        Saldo total adicionado: {{ user.balance.total_balance | currency }}
      </div>
      <div
        v-if="user.balance.old_total_balance > 0"
        class="mt-1"
      >
        Antigo Saldo total adicionado: {{ user.balance.old_total_balance | currency }}
      </div>
    </b-card-title>
    <TablePagination
      v-model="items"
      :url="`/admin/users/balance/history/${user.id}`"
      :per-page="10"
      :fields="fields"
    >
      <template v-slot:action="data">
        <span v-if="data.items.action === 'addition'">Saldo Adicionado</span>
        <span v-if="data.items.action === 'subtraction'">Saldo Retirado</span>
      </template>

      <template v-slot:amount="data">
        {{ data.items.amount | currency }}
      </template>

      <template v-slot:type="data">
        <span v-if="data.items.type === 'Manually'">Manual</span>
        <span v-else>{{ data.items.type }}</span>
        <span v-if="data.items.old_history"> &nbsp;(Histórico Antigo) </span>
      </template>

      <template v-slot:created_at="data">
        {{ data.items.created_at | date }}
      </template>
    </TablePagination>
  </b-card>
</template>

<script>
import { BCard, BCardTitle } from 'bootstrap-vue'
import moment from 'moment'
import TablePagination from '@/components/TablePagination/Index.vue'

export default {
  components: {
    BCard,
    BCardTitle,
    TablePagination
  },
  filters: {
    currency (val) {
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })

      return formatter.format(val)
    },
    date (val) {
      return moment(val).format('DD/MM/Y HH:mm:ss')
    }
  },
  model: {
    prop: 'user',
    event: 'update:user'
  },
  props: {
    user: {
      type: Object,
      default: {},
      required: true
    },
    refresh: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data: () => ({
    show: false,
    items: [],
    fields: [
      {
        key: 'action',
        label: 'Ação'
      },
      {
        key: 'amount',
        label: 'Valor'
      },
      {
        key: 'type',
        label: 'Tipo de Ação'
      },
      {
        key: 'created_at',
        label: 'Data de realização'
      }
    ]
  }),
  watch: {
    'user.id' () {
      this.show = false

      setTimeout(() => {
        this.show = true
      }, 500)
    },
    refresh (val) {
      if (val) {
        this.show = false

        setTimeout(() => {
          this.show = true
        }, 500)
      }
    }
  }
}
</script>
