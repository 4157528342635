<template>
  <section>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group>
                <b-form-select
                  v-model="filterBy"
                  :options="filterOptions"
                  size="md"
                  class="w-25"
                />
              </b-input-group>
            </b-input-group-prepend>
            <vue-autosuggest
              id="email"
              ref="autocomplete"
              v-model="query"
              class="w-75"
              autofocus
              :suggestions="suggestions"
              :get-suggestion-value="getSuggestionValue"
              :input-props="inputProps"
              @input="onInputChange"
              @selected="onSelected"
            >
              <div
                slot-scope="{ suggestion }"
                style="display: flex; align-items: center"
              >
                {{ suggestion.item.personal_data.full_name }} |
                {{ suggestion.item.email }} |
                {{ suggestion.item.personal_data.phone }} |
                {{ suggestion.item.balance.amount | currency }}
              </div>
            </vue-autosuggest>
          </b-input-group>
        </b-col>

        <b-col
          cols="12"
          class="mt-1"
        >
          <b-form-group
            label="Valor"
            label-for="amount"
          >
            <b-form-input
              id="amount"
              v-model="amount"
              type="number"
              trim
              placeholder="Nome do Serviço"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          class="mt-1"
        >
          <b-form-group
            label="Tipo de Ação"
            label-for="type"
          >
            <b-form-select
              id="type"
              v-model="type"
              :options="typeOptions"
              size="md"
              class="w-100"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            :disabled="loading"
            variant="relief-primary"
            class="mr-1"
            @click.stop="addition()"
          >
            <span v-if="!loading">Adicionar Saldo</span>
            <b-spinner
              v-else
              small
            />
          </b-button>

          <b-button
            :disabled="type !== 'Manually' || loading"
            variant="relief-primary"
            @click.stop="subtraction()"
          >
            <span v-if="!loading">Subtrair Saldo</span>
            <b-spinner
              v-else
              small
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <BalanceHistory
      v-model="selected"
      :refresh="historyRefresh"
    />
  </section>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
  BSpinner
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
import IndexMixin from './mixins/IndexMixin'
import AutoSuggestMixin from './mixins/AutoSuggestMixin'
import BalanceHistory from './BalanceHistory.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
    BSpinner,
    VueAutosuggest,
    BalanceHistory
  },
  mixins: [IndexMixin, AutoSuggestMixin]
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>
