export default {
  filters: {
    currency (val) {
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })

      return formatter.format(val)
    }
  },
  data: () => ({
    loading: false,
    historyRefresh: false,
    filterBy: 'email',
    filterOptions: [
      {
        value: 'name',
        text: 'nome'
      },
      {
        value: 'email',
        text: 'email'
      },
      {
        value: 'phone',
        text: 'Telefone'
      }
    ],
    type: 'Manually',
    typeOptions: [
      {
        value: 'Manually',
        text: 'Ação Manual'
      },
      {
        value: 'Mercado Pago',
        text: 'Mercado Pago'
      },
      {
        value: 'PicPay',
        text: 'PicPay'
      },
      {
        value: 'Pix',
        text: 'Pix'
      }
    ],
    amount: 0
  }),
  methods: {
    async addition () {
      this.loading = true
      const url = '/admin/users/balance/addition'
      const params = {
        user_id: this.selected.id,
        amount: this.amount,
        type: this.type
      }

      await this.$http.post(url, params)
        .finally(() => {
          this.loading = false

          this.historyRefresh = true
          setTimeout(() => { this.historyRefresh = false }, 500)
        })
    },
    async subtraction () {
      this.loading = true
      const url = '/admin/users/balance/subtraction'
      const params = {
        user_id: this.selected.id,
        amount: this.amount
      }

      await this.$http.post(url, params)
        .finally(() => {
          this.loading = false

          this.historyRefresh = true
          setTimeout(() => { this.historyRefresh = false }, 500)
        })
    }
  }
}
